
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@font-face {
  font-family: lato-regular;
  src: url(/assets/font/Lato/Lato-Regular.ttf);
}
@font-face {
  font-family: lato-bold;
  src: url(/assets/font/Lato/Lato-Bold.ttf);
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(/assets/font/montserrat/Montserrat-Medium.otf);
}
@font-face {
  font-family: Cormorant;
  src: url(/assets/font/Cormorant/Cormorant-VariableFont_wght.ttf);
}

$md-mcgpalette0: (
    50 : #e0eafa,
    100 : #c4c4c4,
    200 : #80aaea,
    300 : #4d87e2,
    400 : #266edb,
    500 : #6EB5B1,
    600 : #004dd0,
    700 : #d1d3d6,
    800 : #003ac4,
    900 : #181c24,
    A100 : #e3e7ff,
    A200 : #6EB5B1,
    A400 : #7d91ff,
    A700 : #637bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$drz-webapp-primary: mat.define-palette($md-mcgpalette0);
$drz-webapp-accent: mat.define-palette($md-mcgpalette0, A200, A100, A400);

// The warn palette is optional (defaults to red).
$drz-webapp-warn: mat.define-palette(mat.$red-palette);
// $drz-webapp-typography: mat.define-typography-config( $font-family: "'Montserrat', 'Helvetica Neue', 'sans-serif'" );
$drz-webapp-typography: mat.define-typography-config( $font-family: "'lato-regular'" );

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$drz-webapp-theme: mat.define-light-theme(( color: ( primary: $drz-webapp-primary, accent: $drz-webapp-accent, warn: $drz-webapp-warn, ), typography: $drz-webapp-typography ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($drz-webapp-theme);


:root 
{
// --global-font: "Montserrat", "Helvetica Neue", "sans-serif";
--global-font: "lato-regular";
--global-plan-text-color: #535965;
--global-title-text-color: #6EB5B1;
--color-list-unassign: #D1D3D6;
--color-list-1: #F09300;
--color-list-2: #D91B1B;
--color-list-3: #7CB342;
--color-list-4: #0064D5;
--color-list-5: #8434A8;
--color-list-6: #915A00;
--color-list-7: #00B9DB;
--color-list-8: #77a838;
--color-list-9: #b5523c;
--color-list-10: #a8a638;
--color-list-11: #3894a8;
--color-list-12: #38a85b;
--color-list-13: #c47d0a;
--color-list-14: #9438a8;
--color-list-15: #1cbd95;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--global-font);
}
